import mutations from './mutations';
import actions from './actions';

const state = {
  appRbaclList: []
};

const getters = {
  appRbaclList: stateData => stateData.appRbaclList
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
