<template>
  <cs-view single-view>
    <template v-slot:content>
      <h1 class="mt-3 mb-5">{{ $t('app.name') }}</h1>
      <h2 class="mt-3 mb-5">PolicyConnect</h2>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Search</v-toolbar-title>
        <v-autocomplete
          v-model="select"
          :items="items"
          :search-input.sync="search"
          class="mx-4"
          light
          clearable
          hide-details
          hide-selected
          item-text="name"
          placeholder="eg. schoola.policyconnect.com.au"
          solo
          auto-select-first
          hide-no-data
          :disabled="isNewRbacl || !isSearchReady"
        >
        </v-autocomplete>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              id="add-app-uri-btn"
              class="pa-6 mr-3"
              fab
              dark
              color="light-blue lighten-1"
              small
              @click="onAddBtn"
              :disabled="!!select || !search"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>
          </template>
          <span>Add a new RBACL</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              id="delete-app-uri-btn"
              class="pa-6"
              fab
              dark
              color="light-blue lighten-1"
              small
              @click="deleteConfirmationDialog = true"
              :disabled="!select || isNewRbacl"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon dark>
                mdi-minus
              </v-icon>
            </v-btn>
          </template>
          <span>Delete a RBACL</span>
        </v-tooltip>
      </v-toolbar>
      <cs-dialog
        id="delete-confirmation-dialog"
        v-model="deleteConfirmationDialog"
        transition="dialog-bottom-transition"
        scrollable
        max-width="500px"
      >
        <template v-slot:dialog-header>
          <span>Delete RBACL Confirmation Dialog</span>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="deleteConfirmationDialog = false" color="white">
            <v-icon>close</v-icon>
          </v-btn>
        </template>
        <template v-slot:dialog-body-text>
          <div class="mt-10">
            <p>Are you sure to delete {{ select }} RBACL ?</p>
          </div>
        </template>
        <v-spacer></v-spacer>
        <template v-slot:dialog-actions>
          <v-btn id="delete-rbacl-btn-yes" text @click="onYesDeleteRBACLBtn">
            Yes
          </v-btn>
          <v-btn text color="primary" @click="deleteConfirmationDialog = false">
            No
          </v-btn>
        </template>
      </cs-dialog>

      <div class="mt-8">
        <v-text-field label="Selected Site URI" v-model="select" outlined readonly></v-text-field>
      </div>
      <div>
        <textarea id="editor"></textarea>
      </div>
      <div class="mt-8">
        <v-btn
          id="create-update-rbacl-btn"
          elevation="2"
          class="mr-5"
          color="primary"
          @click="onSaveBtn"
          :loading="isLoading()"
          :disabled="(editor && !editor.getValue()) || !select"
        >
          {{ isNewRbacl ? 'Create' : 'Update' }}
        </v-btn>
        <v-btn id="clear-rbacl-btn" elevation="2" @click="onClearBtn">Clear</v-btn>
      </div>
    </template>
  </cs-view>
</template>

<script>
import { CSBase } from '@complispace/cs-design-system';
import { mapGetters } from 'vuex';
import * as CodeMirror from 'codemirror';

export default {
  name: 'Home',

  extends: CSBase,

  data() {
    return {
      items: [],
      search: null,
      select: null,
      editor: null,
      isNewRbacl: false,
      deleteConfirmationDialog: false,
      isSearchReady: false
    };
  },

  async mounted() {
    try {
      this.editor = CodeMirror.fromTextArea(document.getElementById('editor'), {
        lineNumbers: true,
        lineWrapping: true,
        mode: 'csacl'
      });
      this.editor.setSize('100%', 500);

      this.$on('selected', async () => {
        try {
          this.clearAlert();
          this.editor.setValue('');
          const rbacl = await this.$store.dispatch('policy-connect/readRbacl', this.select);
          this.editor.setValue(rbacl);
          this.clearLoading();
        } catch (err) {
          this.showErrorAlert(err.message);
        }
      });

      await this.$store.dispatch('policy-connect/initAppRbaclList');
      this.isSearchReady = true;
    } catch (err) {
      this.showErrorAlert(err.message);
    }
  },

  watch: {
    select(val) {
      if (this.isNewRbacl) {
        return;
      }

      if (!val) {
        this.editor.setValue('');
        this.clearAlert();
        return;
      }

      this.$emit('selected');
      this.setLoading(true);
    },

    search(val) {
      // eslint-disable-next-line no-unused-expressions
      val && val !== this.select && this.querySelections(val);
    }
  },

  methods: {
    querySelections(v) {
      const self = this;
      this.setLoading(true);
      // Simulated ajax query
      setTimeout(() => {
        this.items = self.appRbaclList.filter(e => {
          return (e || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1;
        });
        self.clearLoading();
      }, 500);
    },

    onAddBtn() {
      this.clearAlert();
      this.select = this.search;
      this.isNewRbacl = true;
      this.clearLoading();
      this.editor.focus();
    },

    async onYesDeleteRBACLBtn() {
      this.clearAlert();
      this.setLoading(true);
      try {
        const { select } = this;
        await this.$store.dispatch('policy-connect/deleteRbacl', this.select);
        this.onClearBtn();
        this.deleteConfirmationDialog = false;
        this.showSuccessAlert(`${select} RBACL deleted.`);
      } catch (err) {
        this.showErrorAlert(err.message, true);
      }
      this.clearLoading();
    },

    async onSaveBtn() {
      this.clearAlert();
      this.setLoading(true);
      try {
        const rbacl = this.editor.getValue();
        await this.$store.dispatch('policy-connect/saveRbacl', { appUri: this.select, rbacl });
        if (this.isNewRbacl) {
          this.showSuccessAlert(`${this.select} RBACL created.`);
          this.select = '';
          this.editor.setValue('');
        } else {
          this.showSuccessAlert(`${this.select} RBACL updated.`);
        }
      } catch (err) {
        this.showErrorAlert(err.message);
      }
      this.clearLoading();
      this.isNewRbacl = false;
    },

    async onClearBtn() {
      this.clearAlert();
      this.search = '';
      this.select = '';
      this.isNewRbacl = false;
      await this.$store.dispatch('policy-connect/initAppRbaclList');
    }
  },

  computed: {
    ...mapGetters({
      user: 'cs-session/user',
      appRbaclList: 'policy-connect/appRbaclList'
    })
  }
};
</script>

<style scoped>
h1 {
  text-align: center;
}

h2 {
  text-align: center;
}

.code {
  text-align: left;
}

.rbacls-textarea {
  width: 600px;
}

.token {
  text-align: left;
}

.icon-btn {
  margin-bottom: 10px;
  cursor: pointer;
}
</style>
