const { csManagementApiGatewayPath } = require('./csManagementApiGatewayPath');

exports.csManagementApiGateway = axios => {
  const path = csManagementApiGatewayPath(process.env.VUE_APP_AUTH0_AUDIENCE);

  return {
    rbaclKeys: async () => {
      return axios.get(path.toPolicyConnectRbacls());
    },

    createRbacl: async (appUri, content) => {
      return axios.post(path.toPolicyConnectRbacl(appUri), { rbacl: content });
    },

    readRbacl: async appUri => {
      return axios.get(path.toPolicyConnectRbacl(appUri));
    },

    deleteRbacl: async appUri => {
      return axios.delete(path.toPolicyConnectRbacl(appUri));
    }
  };
};
