/*
    Mutation naming convention.

    UPDATE_ - Update the complete module store.
    SET_ - Update a single property in the module store.
    RESET_ - Clear the module store back to its original values.

    NOTE: We could of reused mutation names across all modules
    e.g. RESET, UPDATE, ADD to avoid types like SESSION_RESET_SESSION,
    but I think in the long run it is better to explicity state each mutation than be generic.
*/

// eslint-disable-next-line import/prefer-default-export
export const SET_APP_RBACL_LIST = 'setAppRbacls';
