import Vue from 'vue';
import CSDesignSystem, {
  vuetify,
  i18n,
  overrideLoginTranslations
} from '@complispace/cs-design-system';
import * as CodeMirror from 'codemirror';
import enLocale from '@/resources/i18n/en.json';
import App from './App';
import router from './router';
import store from './store';
import 'codemirror/lib/codemirror.css';
import 'codemirror/addon/mode/simple';

Vue.config.productionTip = false;

CodeMirror.defineSimpleMode('csacl', {
  start: [
    {
      regex: /"(?:[^\\]|\\.)*?(?:"|$)/,
      token: 'string'
    },
    {
      regex: /^#.*$/,
      token: 'csacl-comment-line'
    },
    {
      regex: /^(ACCEPT)\b/,
      token: 'csacl-predicate-accept'
    },
    {
      regex: /^(DENY)\b/,
      token: 'csacl-predicate-deny'
    },
    {
      regex: /\b(NOT|IN|AND|OR|IS|EQUALS|CONTAINS)\b/,
      token: 'csacl-operator'
    },
    {
      regex: /\b(BEGINS|ENDS) WITH\b/,
      token: 'csacl-operator'
    },
    {
      regex: /\b(TRUE)/,
      token: 'csacl-assertion-true'
    },
    {
      regex: /\b(FALSE)/,
      token: 'csacl-assertion-false'
    },
    {
      regex: /^\[[a-zA-Z\s0-9_-]+\]/,
      token: 'csacl-group-name'
    },
    {
      regex: /\b(IN|NOT IN|INTERSECTS WITH|NO INTERSECTION WITH|SUBSET OF|NOT SUBSET OF)\b/,
      token: 'csacl-operator'
    },
    {
      regex: /\b(UPPER|LOWER)\b/,
      token: 'csacl-function'
    },
    {
      regex: /\b(AUTHENTICATED|COMPLISPACE STAFF|AUTHENTICATED|MEMBER OF|CN|GROUPS)\b/,
      token: 'keyword'
    },
    {
      regex: /\b(FIRST NAME|LAST NAME|DISPLAY NAME|EMAIL ADDRESS|USER ID|OBJECT GUID|PROVIDER|DIRECTORY|USER CONTEXT|SITE CODE)\b/,
      token: 'keyword'
    }
  ]
});

const options = {
  store,
  router,
  auth0: {
    appHost: window.location.host,
    appUrl: window.location.origin,
    auth0Domain: process.env.VUE_APP_AUTH0_DOMAIN,
    auth0ClientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
    auth0Audience: process.env.VUE_APP_AUTH0_AUDIENCE
  }
};

// Internationalization
const { messages } = i18n;
Object.keys(messages).forEach(key => {
  if (key === 'en') {
    const combined = { ...enLocale, ...messages[key] };
    const overridden = overrideLoginTranslations(combined, {
      loginViewTitle: enLocale.login.title,
      loginViewSubTitle: enLocale.login.subTitle
    });

    i18n.setLocaleMessage(key, overridden);
  } else {
    const merged = messages[key];
    const overridden = overrideLoginTranslations(merged, {
      loginViewTitle: enLocale.login.title,
      loginViewSubTitle: enLocale.login.subTitle
    });

    i18n.setLocaleMessage(key, overridden);
  }
});

// Complispace design system
Vue.use(CSDesignSystem, options);

new Vue({
  i18n,
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app');
