import { axios } from '@complispace/cs-design-system';
import * as types from '@/store/mutationTypes';
import { csManagementApiGateway } from '../../../api/csManagementApiGateway';

const initAppRbaclListImpl = async ({ commit }) => {
  const gateway = csManagementApiGateway(axios);
  const response = await gateway.rbaclKeys();
  commit(types.SET_APP_RBACL_LIST, response.data.rbaclKeys);
};

export default {
  async initAppRbaclList({ commit }) {
    await initAppRbaclListImpl({ commit });
  },

  async readRbacl(unused, appUri) {
    if (!appUri) {
      throw new Error(`failed to read Rbacl because of missing appUri ${appUri}`);
    }

    const gateway = csManagementApiGateway(axios);
    const response = await gateway.readRbacl(appUri);
    return response.data.rbacl;
  },

  async saveRbacl({ commit }, { appUri, rbacl }) {
    if (!appUri || !rbacl) {
      throw new Error(`failed to save Rbacl because of missing appUri ${appUri} rbacl ${rbacl}`);
    }

    const gateway = csManagementApiGateway(axios);
    await gateway.createRbacl(appUri, rbacl);
    await initAppRbaclListImpl({ commit });
  },

  async deleteRbacl({ commit }, appUri) {
    if (!appUri) {
      throw new Error(`failed to read Rbacl because of missing appUri ${appUri}`);
    }

    const gateway = csManagementApiGateway(axios);
    await gateway.deleteRbacl(appUri);
    await initAppRbaclListImpl({ commit });
  }
};
