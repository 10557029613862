import * as types from '../../mutationTypes';

export default {
  /* eslint-disable no-param-reassign */

  [types.SET_APP_RBACL_LIST](state, appRbaclList) {
    state.appRbaclList = appRbaclList;
  }

  /* eslint-enable no-param-reassign */
};
