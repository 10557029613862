<template>
  <cs-app
    :showHeader="isLoggedIn"
    :sideNavWidth="sideNavWidth"
    :version="version"
    :appLogoUri="csAppLogo"
  >
    <template v-slot:side-nav> </template>

    <template v-slot:router-view>
      <v-slide-y-transition>
        <router-view></router-view>
      </v-slide-y-transition>
    </template>

    <template v-slot:footer>
      <div class="content">
        <v-layout align-center justify-center column fill-height>
          <v-img :src="csLogo" class="cs-logo"> </v-img>
          <span id="version-label">
            &copy;{{ copyRightYearRange }} CompliSpace (v{{ version }}). All rights reserved.
          </span>
        </v-layout>
      </div>
    </template>
  </cs-app>
</template>

<script>
import { mapGetters } from 'vuex';
import { CSBase } from '@complispace/cs-design-system';
import csLogo from './assets/complispace-logo.png';
import csAppLogo from './assets/CompliSpace.Logo.png';
import { version } from '../package.json';

export default {
  name: 'App',

  extends: CSBase,

  data() {
    return {
      csLogo,
      csAppLogo
    };
  },

  mounted() {},

  created() {
    this.setViewTitle(this.$t('app.name'));
  },

  computed: {
    ...mapGetters({
      isLoggedIn: 'cs-session/isLoggedIn'
    }),

    sideNavWidth() {
      return 0;
    },

    version() {
      return version;
    },

    copyRightYearRange() {
      const startYear = '2018';
      return `${startYear}-${new Date().getFullYear().toString()}`;
    }
  }
};
</script>

<style>
.CodeMirror {
  font-family: monospace !important;
  font-size: 14px !important;
}

.cm-csacl-operator {
  color: royalblue;
}
.cm-csacl-group-name {
  color: darkolivegreen;
  font-weight: bold;
}
.cm-csacl-predicate-accept {
  color: green;
  font-weight: bold;
}
.cm-csacl-predicate-deny {
  color: purple;
  font-weight: bold;
}
.cm-csacl-assertion-true {
  color: lightseagreen;
}
.cm-csacl-assertion-false {
  color: violet;
}
.cm-csacl-function {
  color: blue;
}
.cm-csacl-comment-line {
  color: lightslategray;
}

.v-footer.theme--light.v-sheet {
  background-color: white;
}

.v-footer {
  justify-content: space-around;
}
</style>
<style scoped>
.cs-logo {
  width: 200px;
  height: 50px;
}

.cs-view {
  max-width: 1000px;
}
</style>
