exports.csManagementApiGatewayPath = baseUri => {
  return {
    toPolicyConnectRbacls: () => `${baseUri}policy-connect/rbacls`,
    toPolicyConnectRbacl: appUri => {
      if (!appUri) {
        throw new Error(`failed to return policy connect rbacl path due to missing app uri`);
      }

      return `${baseUri}policy-connect/rbacl?appUri=${appUri}`;
    }
  };
};
